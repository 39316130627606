import React,{useEffect} from 'react';
import { Row, Col,Button} from 'react-bootstrap';
import resourcesdata from '../pagedata/resources';
import styled from 'styled-components';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import AOS from 'aos';
import "aos/dist/aos.css";

const PageWrapper = styled.div``;
const Hero = styled.section``;
const Resource = styled.section``;

function Resources (){
  useEffect(() => {
    AOS.init({
        duration: 1000,
        useClassNames: true,
        initClassName: false,
        easing: 'linear',
    });
    AOS.refresh();
}, []);


  return(
    <>
    <Masthead/>
  <PageWrapper className="container-xxl resources p-0">
    <Hero className='container-fluid herobanner'>
      <Row className="justify-content-center">
        <Col lg="10">
        <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
            <Col lg="6" className='left pr-lg-5 text-center text-lg-left'>
              <h1>{resourcesdata.page.hero.heading}</h1>
              <p dangerouslySetInnerHTML={{ __html: resourcesdata.page.hero.subhead }}></p>
              <Button variant="primary" className='rounded-3 pr-4 pl-4 mt-3 d-none' href={resourcesdata.page.hero.ctalink}>{resourcesdata.page.hero.ctatext}</Button>{' '}
            </Col>
            <Col lg="6" className='pl-5'><img src={resourcesdata.page.hero.banner} className='img-fluid' alt=""/></Col>
          </Row>
        </Col>
      </Row>
    </Hero>

    <Resource className='container-fluid pt-5 pb-5' data-aos="fade-up">
    <Row className='justify-content-center'>
      <Col lg="10">

      <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
        <Col lg="12" className='left pr-lg-5 text-center text-lg-left'>
          <Row>
            {resourcesdata.page.resources.items1.map((item, index) => (
                <Col lg="4" key={index} className='mb-5'>
                  <h5> {item.heading}</h5>
                  <p className='border text-center'><img src={item.img} alt={item.heading}/></p>
                </Col>
              ))}
            </Row>
        </Col>
      </Row>

    <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
      <Col lg="12" className='pr-lg-5 text-center text-lg-left'>
        <Row className='justify-content-center' >
          {resourcesdata.page.resources.items2.map((item, index) => (
            <Col lg="4" key={index} className='mb-5'>
            <h5> {item.heading}</h5>
            <p className='border text-center'><img src={item.img} alt={item.heading}/></p>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>

      </Col>
    </Row>
  </Resource>
  </PageWrapper>
  <Footer/>
  </>
  )
  }

  export default Resources;

