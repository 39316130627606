import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row, Button } from 'react-bootstrap';
import about from '../pagedata/about';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import AOS from 'aos';
import "aos/dist/aos.css";
import ArkLogo from "../Assets/images/ArkLogo.png";

const PageWrapper = styled.div``;
const Hero = styled.section``;
const AboutUs = styled.section``;
const Innovation = styled.section``;
const Vision = styled.section``;

function About() {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      useClassNames: true,
      initClassName: false,
      easing: 'linear',
    });
    AOS.refresh();
  }, []);


  return (
    <>
      <Masthead />
      <PageWrapper className="container-xxl about p-0">
        <Hero className='container-fluid herobanner'>
          <Row className="justify-content-center">
            <Col lg="10">
              <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
                <Col lg="6" className='left pr-lg-5 text-center text-lg-left'>
                  <h1>{about.page.hero.heading}</h1>
                  <p>{about.page.hero.subhead}</p>
                  <Button variant="primary" className='rounded-3 pr-4 pl-4 mt-3' href={about.page.hero.ctalink}>{about.page.hero.ctatext}</Button>{' '}
                </Col>
                <Col lg="6" className='pl-lg-5'><img src={about.page.hero.banner} className='img-fluid' alt="" /></Col>
              </Row>
            </Col>
          </Row>
        </Hero>

        <AboutUs className='container-fluid aboutus' data-aos="fade-up">
          <Row className='justify-content-center pt-5 pb-5'>
            <Col lg="10">
              <Row className="justify-content-left pr-5 pl-5 pr-lg-0 pl-lg-0">
                <Col lg="8" className='left pr-lg-5 text-center text-lg-left'>
                  <p dangerouslySetInnerHTML={{ __html: about.page.about }}></p>
                </Col>
                <Col lg="4" className='pl-lg-5'>
                  <img src={ArkLogo} className='img-fluid' width="200" alt="" /></Col>

              </Row>
            </Col>
          </Row>
        </AboutUs>

        <Innovation className='container-fluid innovation' data-aos="fade-up">
          <Row className='justify-content-center pt-5 pb-5'>
            <Col lg="10">
              <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
                <Col lg="8" className='left pr-lg-5 text-center text-lg-left'>
                  {about.page.innovation.items.map((item, index) => (
                    <div key={index}>
                      <h4 className='pt-3'>{item.heading}</h4>
                      <p className='pb-3'>{item.description}</p>
                    </div>
                  ))}
                </Col>
                <Col lg="4" className="text-center text-lg-left" data-aos="fade-up">
                  <img src={about.page.innovation.image} className='img-fluid' alt="" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Innovation>

        <Vision className='container-fluid vision' data-aos="fade-up">
          <Row className='justify-content-center pt-5 pb-5'>
            <Col lg="8">
              <Row className="container">
                <Col lg="5">
                </Col>
                <Col lg="7" className='text-center'>
                  {about.page.vision.items.map((item, index) => (
                    <div key={index} className='pt-5 pb-3'>
                      <h4> {item.heading}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </Col>

              </Row>
            </Col>
          </Row>
        </Vision>

      </PageWrapper>
      <Footer />
    </>
  )
}
export default About;
