import contactbanner from "../Assets/images/contactbanner.png";
import thankyouicon from "../Assets/images/thankyouicon.png";

const contactdata = {
    "page":{
        "title": "Contact",
        "hero": {
            "heading": "Contact Us",
            "subhead": "Reach us at",
            "banner":contactbanner
            },   
        "contactform": {
                "fullName":"Full Name",
                "fullNamePH":"Write your full name",
                "fullNameError":"Please provide your full name.",
                "orgName":"Company Name",
                "orgNamePH":"Write your company name",
                "orgNameError":"Please provide valid company name.",
                "orgWebsite":"Company Website",
                "orgWebsitePH":"eg: https://companyname.com/",
                "orgWebsiteError":"Please provide valid url eg: https://companyname.com.",
                "country":"Country",
                "countryPH":"Your country name",
                "countryErr":"Please provide valid country name",
                "businessemail":"Business Email",
                "businessemailPH":"Your valid email id",
                "businessemailErr":"Please provide valid email address",
                "businessphone":"Business Phone",
                "businessphonePH":"Your Phone Number",
                "businessphoneErr":"Please provide valid phone number",
                "message":"Your Message",
                "messagePH":"Your Remarks",
                "messageErr":"Please provide your remarks not less than 250 characters",
                "submit":"Submit",
                "thankyouicon":thankyouicon               
                },
            "preference":{
                "text":"What kind of Emails would you prefer?",
                "items":[
                    {"preftype":"Construction Related"},
                    {"preftype":"Offers"},
                    {"preftype":"Investment"}
                ]
            }
        }
  };
    
  export default contactdata;