import careersbanner from "../Assets/images/careersbanner.png";

const careersdata = {
  "page": {
    "title": "Careers",
    "hero": 
      {
        "heading": "Thinking to join Ark of various solution?",
        "subhead": "",
        "banner":careersbanner
      },  
      "about":"Thinking to join Ark Team? Share your profile to <a href='mailto:careers@arkpetals.com'>careers@arkpetals.com</a>" 
  }
};

export default careersdata;