const faqdata = {
  "page": {
    "title": "FAQ",
    "faqs": [
      {
        "question": "What support and service packages are available?",
        "answer": " We provide basic and priority support. You can refer to the details of these supports from this link below. (Link content will be provided after support team head gives the data)"
      },
      {
        "question":"Do “Ark Docs” products integrate with external applications and systems?",
        "answer":"We will provide a KB article that will contain step-by-step information about how to integrate Ark Docs with other external applications. Note that 1:1 support is chargeable. Please contact the sales team for 1:1 service."
      },    
      {
        "question": "Can I upgrade at any time? Can I add more products later on? ",
        "answer": "Yes. All the released versions can be upgraded to the latest version. Read the instruction document before upgrading."
      },    
      {
        "question": "Can I access Office 365 License while using the application?",
        "answer": "Yes. The current logged in user details of Office 365 License is used to access the document from Ark Docs platform."
      },    
      {
        "question": "How can we import documents through email?",
        "answer": "Use the plugin provided for this feature. You can import different documents to any folder of your choice."
      },  
      {
        "question": "How safe and secure is my document on Ark Docs platform?",
        "answer": "Ark Docs platform uses highly-secured encryption mechanism to protect and secure your documents."
      },
      {
        "question": "How do I access the help files?",
        "answer": "We have integrated the help files in the application for easy access."
      }         
    ]
  }
};

export default faqdata;