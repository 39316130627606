import React,{useState} from 'react';
import { Accordion, Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

function AccordionDisplay({children,title,forceDefaultHide = false}) {
const [isOpen, setIsOpen] = useState(true);
function toggle() {
  setIsOpen(!isOpen);
}

return (
  <Accordion defaultActiveKey="0">
    <Card>
     <Accordion.Toggle as={Card.Header} eventKey={forceDefaultHide ? "1" : "0"} onClick={toggle}>
          <Row>
            <Col className="col-11 px-3">
              <div className="d-flex align-items-center" style={{ height: "100%" }}>
                {title}
              </div>
            </Col>
            <Col className="col-1 align-self-center pl-2 py-2">
                {isOpen ? <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon> : <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>}
             </Col>
            </Row>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={forceDefaultHide ? "1" : "0"}>       
      <Row>
            <Col className="col-10 px-3">
          <Card.Body>
            <div>{children}</div>
          </Card.Body>
          </Col>
            </Row>
     </Accordion.Collapse>
     </Card>
    </Accordion>
  );
}

export default AccordionDisplay;