import herobanner from "../Assets/images/abouthero.png";
import innovation from "../Assets/images/innovation.png";
import vision from "../Assets/images/vision.png";

const about = {
  "page": {
    "title": "About Us",
    "hero": 
      {
        "heading": "Customer delight is the key to our growth and success.",
        "subhead": "Ark Docs is a Rapid developed platform which support all your business needs within no time.",
        "banner":herobanner,
        "ctatext":"Learn More",
        "ctalink":"/solutions"
      },
      "about":"<p>Established in 2023, <strong>Ark Petals Software Private Limited</strong> is an Enterprise Content Management (ECM) Services Platform that offers ready to use product suite for Document Management System, Workflow and Process Automation solutions across various industries. We are a strongly growing company with a vision to improve customer experience and a mission to provide innovative solutions that caters to business needs across the globe.</p>",
     
    "innovation": {
      "items": [
        {
          "heading": "Innovation is our culture",
          "description": "As a product company, we believe that innovation is the solution key to every problem. We constantly enrich our product to be a vision of futuristic, scalable offerings to address different challenges. We encourage every person in our R&D team to be innovative in what they do. We are an organization driven by values and delightful experience for our customer always.",
        },
        {
          "heading": "Integrity is our pride",
          "description": "As a company, we believe integrity is a virtue and we stand by it. We’re whole and undivided when what we think, what we feel, and what we do are in alignment. We encourage every employee to demonstrate integrity of the highest level at the workplace and beyond.",
        }
      ],
      "image":innovation
    },
    "vision": {
      "items": [
        {
          "heading": "Our Vision",
          "description": "To be a digitally transformed enterprise, empowering and leading our customers in their journey into the digital era.",
        },
        {
          "heading": "Our Mission",
          "description": "To continuously innovate and revolutionize the use of technology in Document Management Systems and business processes.",
        }
      ],
      "image":vision
    }
  }
};

export default about;