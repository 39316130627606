import feature1 from "../Assets/images/keyfeatures1.png";
import feature2 from "../Assets/images/keyfeatures2.png";
import feature3 from "../Assets/images/keyfeatures3.png";
import banner1 from "../Assets/images/banner1.png";
import banner2 from "../Assets/images/banner2.png";
import banner3 from "../Assets/images/banner3.png";
import banner4 from "../Assets/images/banner4.png";
import testimonialbg from "../Assets/images/testimonialbg.jpg";
import client1 from "../Assets/images/clients1.jpg";
import client2 from "../Assets/images/clients2.jpg";

const homepage = {
  "herobanner": {
    "title": "ArkPetals",
    "subtitle": "An innovation-driven construction firm committed to give<br/> your dreams a tangible form.",
    "cta": "Get Enquiry",
    "carousel": [
      {
        "heading":"Use the power of Ark Docs to automate all your documentation needs with a hassle free solution.",
        "subheading":"<strong>Ark Docs</strong> is a rapid documentation development platform to support all your business needs within no time.",
        "imagesrc": banner1,
        "ctatext":"Learn more",
        "ctalink":"/solutions"
      },
      {
        "heading":"Achieve better process excellence in your business processes with Ark Docs for all your enterprise contents.",
        "subheading":"<strong>Ark Docs</strong> is a rapid documentation development platform to support all your business needs within no time.",
        "imagesrc": banner2,
        "ctatext":"Learn more",
        "ctalink":"/solutions"
      },
      {
        "heading":"Use the power of Ark Docs to automate all your documentation needs with a hassle free solution.",
        "subheading":"<strong>Ark Docs</strong> is a rapid documentation development platform to support all your business needs within no time.",
        "imagesrc": banner3,
        "ctatext":"Learn more",
        "ctalink":"/solutions"
      },
      {
        "heading":"Achieve better process excellence in your business processes with Ark Docs for all your enterprise contents.",
        "subheading":"<strong>Ark Docs</strong> is a rapid documentation development platform to support all your business needs within no time.",
        "imagesrc": banner4,
        "ctatext":"Learn more",
        "ctalink":"/solutions"
      },
    ]
  },
  "keyfeatures": {
    "title": "Key Features",
    "items": [
      {
        "imagesrc": feature1,
        "text": "Every organization has its storage needs that change over time. With Ark Docs, you can:",
        "features":"<ul><li>Have a centralized, secure, and organized repository for all your important documentation in your organization.</li><li>Use version control mechanism to track changes over time in your documentation repository.</li><li>Streamline document information across the enterprise and ensure greater effectiveness in workplace.</li><li>Falicitate team collaboration through real-time editing, commenting, and annotation on documents within the platform.</li>      <li>Upgrade or downgrade your plan based on your team size seamlessly with Ark Docs.</li></ul>"
      },
      {
        "imagesrc": feature2,
        "text": "Work is no longer limited to office space. Create files and collaborate with the team from anywhere, using Ark Docs application using desktop or mobile phones.",
        "features":""
      },
      {
        "imagesrc": feature3,
        "text": "Efficiently search and retrieve your files. Quickly locate and access documents using Optical Character Recognition (OCR)  search, labels, favorites, metadata, keywords, content tagging in Ark Docs.",
        "features":""
      },
    ]
  },
  "testimonials": {
    "title": "Testimonials",
    "items": [
      {
        "imagesrc": testimonialbg,
        "text": "“Coming Soon”",
        "name":""
      },
      {
        "imagesrc": testimonialbg,
        "text": "“Coming Soon”",
        "name":""
      },
      {
        "imagesrc": testimonialbg,
        "text": "“Coming Soon”",
        "name":""
      },
    ]
  },
  "clients": {
    "title": "Clients",
    "items": [
      {
        "imagesrc": client1,
        "text": "Choose a Package",
      },
      {
        "imagesrc": client2,
        "text": "Book with Advance",
      }
    ]
  }
};

export default homepage;