import React,{useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import solutionsdata from '../pagedata/solutions';
import styled from 'styled-components';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import AccordionDisplay from '../components/Accordion';
import AOS from 'aos';
import "aos/dist/aos.css";

const PageWrapper = styled.div``;
const Hero = styled.section``;
const KeyFeatures = styled.section``;
const Solution = styled.section``;

function Solutions(){

  useEffect(() => {
    AOS.init({
        duration: 1000,
        useClassNames: true,
        initClassName: false,
        easing: 'linear',
    });
    AOS.refresh();
}, []);

  return(
    <>
    <Masthead/>
  <PageWrapper className="container-xxl solutions">
    <Hero className='container-fluid herobanner'>
      <Row className="justify-content-center ">
        <Col lg="10">
          <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
            <Col lg="6" className='left pr-lg-5 text-center text-lg-left'>
              <h1>{solutionsdata.page.hero.heading}</h1>
              <p>{solutionsdata.page.hero.subhead}</p>
            </Col>
            <Col lg="6" className='pl-lg-5 text-center text-lg-left'><img src={solutionsdata.page.hero.banner} className='ml-1 img-fluid' alt=""/></Col>
          </Row>
        </Col>
      </Row>
    </Hero>

  <Solution className='container-fluid pt-5 pb-5' data-aos="fade-up"> 
    <Row className='justify-content-center'>
      <Col lg="10">
      <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
            <Col lg="8" className='left pr-lg-5 text-center text-lg-left'>
      {solutionsdata.page.solutions.items.map((item, index) => (
        <div key={index}>
          <h4> {item.heading}</h4>
          <p>{item.description}</p>
        </div>
      ))}
      </Col>
      <Col lg="4">
       <img src={solutionsdata.page.solutions.image} className='img-fluid' alt=""/>
      </Col>
    </Row>
    </Col>
    </Row>
  </Solution>
     
  <KeyFeatures className='container-fluid  bg-light pt-5 pb-5' data-aos="fade-up">
  <Row className='justify-content-center'>
  <Col lg="10">
  <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
      <Col lg="10" className='left pr-lg-5 text-center text-lg-left'>
        <h4 className='pb-4 text-center'>Key Features</h4>
      {solutionsdata.page.keyfeatures.items.map((i, index) => (
      <Row key={index}>
          <Col>
          <AccordionDisplay title={i.heading} arrowPosition="left" forceDefaultHide={true}>
                <div>
                <p dangerouslySetInnerHTML={{ __html: i.description }}></p>
                </div>
              </AccordionDisplay>
          </Col>
      </Row>
      ))}
      </Col>
      <Col lg="2">
      </Col>
    </Row>
    </Col>
    </Row>
  </KeyFeatures>
  </PageWrapper>
  <Footer/>
  </>
  )
  }

  export default Solutions;
