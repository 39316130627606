import React,{useState,useEffect} from 'react';
import {Row,Col,Form,Button,Fragment} from 'react-bootstrap';
import contactdata from '../pagedata/contact';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';
import AOS from 'aos';
import "aos/dist/aos.css";
const PageWrapper = styled.div``;
const Hero = styled.section``;
const ContactForm = styled.section``;

const renderAlert = () => (
  <div className="px-4 py-3 mb-5 text-center success">
    <figure><img src={contactdata.page.contactform.thankyouicon} alt=""/></figure>
    <h3>Thank you for your interest!</h3>
    <h4>We will get back to you shortly</h4>
  </div>
)

function Contact () {
  const queryParameters = new URLSearchParams(window.location.search)
  const requesttype = queryParameters.get("reqtype")

  const [values, setValues] = useState({
    fullName: '',
    orgName: '',
    orgWebsite: '',
    country: '',
    businessEmail: '',
    businessPhone:'',
    message:'',
    requesttype:'Enquiry'
  }); 
  const [status, setStatus] = useState('');
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } 
    
    setValidated(true);
    if (form.checkValidity() === true) {
    e.preventDefault();
    emailjs.send('service_488q21s', 'template_ztxpiyy', values,'dRE1RE3mqZHl1aOIY')
      .then(response => {
        console.log('SUCCESS!', response);
        setValues({
          fullName: '',
          orgName:'',
          orgWebsite:'',
          country:'',
          businessEmail: '',
          businessPhone: '',
          message: '',
          requesttype:''
        });
        setStatus('SUCCESS');
      }, error => {
        console.log('FAILED...', error);
      })
    }
}

/*
  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 20000);
    }
  }, [status]);
*/

  useEffect(() => {
    AOS.init({
        duration: 1000,
        useClassNames: true,
        initClassName: false,
        easing: 'linear',
    });
    AOS.refresh();
}, []);


  const handleChange = (e) => {
    //console.log(e.target.value);
    setValues(values => ({
      ...values,
      [e.target.name]:e.target.value
    }))
  }
  return(
    <>
    <Masthead/>
  <PageWrapper className="container-xxl contact p-0">
  <Hero className='container-fluid herobanner'>
    <Row className="justify-content-center">
      <Col lg="10">
      <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
            <Col lg="6" className='left pr-lg-5 text-center text-lg-left'>
            <h1>{contactdata.page.hero.heading}</h1>
            <p>{contactdata.page.hero.subhead}</p>
          </Col>
          <Col lg="6" className='pl-5'><img src={contactdata.page.hero.banner} className='img-fluid' alt=""/></Col>
        </Row>
      </Col>
    </Row>
  </Hero>

  <ContactForm className='container-fluid pt-5 pb-5' data-aos="fade-up">
    <Row className='justify-content-center'>
      <Col lg="6">
      {status && renderAlert()}
      {!status && 
      <Form  noValidate validated={validated} className='pt-3 pb-3' onSubmit={handleSubmit}>
      <Row className='pb-3'>
        <Col lg="6">
       
        <Form.Check type="radio" id="" label="Enquiry" required name='requesttype' onChange={handleChange} value="Enquiry" defaultChecked={!requesttype ? 'checked' : ''} />
        </Col>
        <Col lg="6">
        <Form.Check type="radio" id="" label="Request for Demo" name='requesttype' onChange={handleChange} value="Request for Demo" defaultChecked={requesttype ? 'checked' : ''} />
        </Col>
        </Row>
        <Row>
        <Col lg="6">
          <Form.Group className="p-2" controlId="contactForm.fullName">
              <Form.Label>{contactdata.page.contactform.fullName}</Form.Label>
              <Form.Control type="text" onChange={handleChange} required value={values.fullName} placeholder={contactdata.page.contactform.fullNamePH} name="fullName" className=' rounded-0' minLength={5} />
              <Form.Control.Feedback type="invalid">{contactdata.page.contactform.fullNameError}</Form.Control.Feedback>
          </Form.Group>
          </Col>
          <Col lg="6">
          <Form.Group className="p-2" controlId="contactForm.orgname">
              <Form.Label>{contactdata.page.contactform.orgName}</Form.Label>
              <Form.Control type="text" onChange={handleChange} required value={values.orgName} name="orgName" placeholder={contactdata.page.contactform.orgNamePH} className=' rounded-0' minLength={5}/>
              <Form.Control.Feedback type="invalid">{contactdata.page.contactform.orgNameError}</Form.Control.Feedback>
          </Form.Group>
          </Col>
          </Row>
          
          <Row>
          <Col lg="6">
            <Form.Group className="p-2" controlId="contactForm.orgWebsite">
                <Form.Label>{contactdata.page.contactform.orgWebsite}</Form.Label>
                <Form.Control type="text"  pattern="https://.*" onChange={handleChange} value={values.orgWebsite} placeholder={contactdata.page.contactform.orgWebsitePH} name="orgWebsite" className=' rounded-0' />
                <Form.Control.Feedback type="invalid">{contactdata.page.contactform.orgWebsiteError}</Form.Control.Feedback>
            </Form.Group>
            </Col>
            <Col lg="6">
            <Form.Group className="p-2" controlId="contactForm.country">
                <Form.Label>{contactdata.page.contactform.country}</Form.Label>
                <Form.Control type="text" onChange={handleChange} value={values.country} name="country" placeholder={contactdata.page.contactform.countryPH} className=' rounded-0' />
                <Form.Control.Feedback type="invalid">{contactdata.page.contactform.countryErr}</Form.Control.Feedback>
            </Form.Group>
            </Col>
            </Row>
           
        <Row>
          <Col lg="6">
            <Form.Group className="p-2" controlId="contactForm.businessEmail">
                <Form.Label>{contactdata.page.contactform.businessemail}</Form.Label>
                <Form.Control type="email" onChange={handleChange} required value={values.businessEmail} name="businessEmail" placeholder={contactdata.page.contactform.businessemailPH} className='rounded-0' />
                <Form.Control.Feedback type="invalid">{contactdata.page.contactform.businessemailErr}</Form.Control.Feedback>
            </Form.Group>
            </Col>
            <Col lg="6">
            <Form.Group className="p-2" controlId="contactForm.businessPhone">
                <Form.Label>{contactdata.page.contactform.businessphone}</Form.Label>
                <Form.Control type="tel" onChange={handleChange}  pattern='^-?[0-9]\d*\.?\d*$' minLength={10} required value={values.businessPhone} name="businessPhone" placeholder={contactdata.page.contactform.businessphonePH} className='rounded-0' />
                <Form.Control.Feedback type="invalid">{contactdata.page.contactform.businessphoneErr}</Form.Control.Feedback>
            </Form.Group>
            </Col>
            </Row>
           
         <Row>
          <Col lg="12">
            <Form.Group className="p-2" controlId="contactForm.message">
              <Form.Label>{contactdata.page.contactform.message}</Form.Label>
              <Form.Control as="textarea" onChange={handleChange} required value={values.message} name="message" placeholder={contactdata.page.contactform.messagePH} rows={3} className=' rounded-0' minLength={250}/>
              <Form.Control.Feedback type="invalid">{contactdata.page.contactform.messageErr}</Form.Control.Feedback>
            </Form.Group>
            </Col>
          
            </Row>
            <Row>
              <Col className='text-right'>
              <Button  className="rounded-2 mt-4 pl-4 pr-4" variant="primary" type='submit'>
              {contactdata.page.contactform.submit}
                </Button>
              </Col>
            </Row>
            </Form>
          }
        </Col>
      </Row>
  </ContactForm>
  </PageWrapper>
  <Footer/>
  </>
  )
}

export default Contact;