import herobanner from "../Assets/images/solutionsbanner.png";
import solutionsimg from "../Assets/images/solutions.png";

const solutionsdata = {
  "page": {
    "title": "Solutions",
    "hero":
    {
      "heading": "Centralized Storage for Enterprises",
      "subhead": "Ark Docs is a Rapid developed platform which support all your business needs within no time.",
      "banner": herobanner
    },
    "solutions": {
      "image": solutionsimg,
      "items": [
        {
          "heading": "Content and Document Management System",
          "description": "The Document Management System or DMS offers a centralized storage for enterprise content through an automated, ready-to-capture platform system with a tons of features. DMS enable organizations to optimize their efficiency in their day-to-day activities. DMS is easy to integrate across various other business applications for smart governance and centralized access to enterprise-wide content within a single platform.",
        },
        {
          "heading": "Workflow Management",
          "description": "Workflow Management System or WMS offers an organization to route tasks dynamically that enables agility in business processes with real time-automation, quick configuration, and user-friendly interface design. WMS enables quick decision making, faster turnaround time, and accuracy across business processes, collaborations, and approvals effectively.",
        },
        {
          "heading": "Records Management",
          "description": "Records Management System of RMS manages the end-to-end lifecycle of documents. RMS is an automated and completely secure system that can be used for different types of records classification, retention, and disposition of files as per the business requirements."
        }
      ]
    },
    "keyfeatures": {
      "title": "Here are some key features for a DMS ARK Docs:",
      "items": [
        {
          "heading": "Document Naming Conventions",
          "description": "<ul><li>Establish a consistent and logical naming convention for your documents for easier search and retrieval of files.</li></ul>",
        },
        {
          "heading": "Folder Structure",
          "description": "<ul><li>Design a clear and intuitive folder structure. Consider organizing documents by project, department, or any other relevant category.</li><li>Include relevant information in the file names, such as date, project name, or document type.</li></ul>",
        },
        {
          "heading": "Metadata",
          "description": "<ul><li>Use metadata to provide additional information about documents. This can include keywords, tags, author information, and version history.</li><li>Metadata enhances search capabilities and helps users understand the context of a document.</li></ul>",
        },
        {
          "heading": "My Zone",
          "description": "<ul><li>A secure and scalable virtual environment, accessible from anywhere at your fingertips. It is designed to improve your productivity and protect your essential data.</li></ul>",
        },
        {
          "heading": "Version Control",
          "description": "<ul><li>Implement version control to track changes and updates to documents. Clearly indicate the latest version and provide access to previous versions if needed.</li><li>Use a systematic approach such as version numbers or dates.</li></ul>",
        },
        {
          "heading": "Document Templates",
          "description": "<ul><li>Develop standardized document templates for common types of files. This ensures consistency across documents and saves time in document creation.</li></ul>",
        },
        {
          "heading": "Security and Permissions",
          "description": "<ul><li>Define access controls and permissions to restrict document access based on user roles. This is crucial for maintaining confidentiality and data security.</li></ul>",
        },
        {
          "heading": "Document Lifecycle",
          "description": "<ul><li>Establish a document lifecycle process, including creation, review, approval, and archival. Clearly define roles and responsibilities at each stage.</li><li>Automate workflows wherever possible to streamline processes.</li></ul>",
        },
        {
          "heading": "Search Functionality: Search and Retrieval",
          "description": "<ul><li>Develop standardized document templates for common types of files. This ensures consistency across documents and saves time in document creation.</li><li>Consider integrating with a powerful search engine for more efficient results.</li></ul>",
        },
        {
          "heading": "Multiple Language Support",
          "description": "<ul><li>Configure languages based on your location or requirement.</li></ul>",
        },
        {
          "heading": "Workflow",
          "description": "<ul><li>Define approval workflows in a system. Workflows can be configured manually for files or based on the document category. Workflow can be of the type Serial or Parallel.</li></ul>",
        },
        {
          "heading": "Document Viewer",
          "description": "<ul><li>Supports different document formats.</li><li>Mask sensitive data in documents using the redaction feature.</li><li>Review multiple versions of documents using different viewer tools for detailed access.</li></ul>",
        },
        {
          "heading": "Signature Configuration ",
          "description": "<ul><li>Use signatures to identify different scenarios for varied purposes. These watermarks will appear based on Document Viewer configuration. You can download these as a PDF file. The downloaded PDF file will contain watermarks. Use the watermarks to display signatures, version information, user information, and so on.</li></ul>",
        },
      ]

    },
  }
};

export default solutionsdata;