import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Solutions from './pages/Solutions';
import Pricing from './pages/Pricing';
import Resources from './pages/Resources';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';

import "./scss/App.scss"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/home" element={<Home />} />
        <Route index path="/about" element={<About />} />
        <Route index path="/solutions" element={<Solutions />} />
        <Route index path="/pricing" element={<Pricing />} />
        <Route index path="/resources" element={<Resources />} />
        <Route index path="/careers" element={<Careers />} />
        <Route index path="/contact" element={<Contact />} />
        <Route index path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route index path="/terms" element={<Terms />} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
