import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
const PageWrapper = styled.div``;
const TermsServices = styled.section``;


function Terms() {
  return(
    <>
    <Masthead/>
  <PageWrapper className="container-xxl about p-0">
    <TermsServices className='container-fluid  pb-5'>
      <Row className="justify-content-center pt-5 pb-5">
            <Col lg="10"> <br/> <br/> <br/> <br/> <br/>
            <h1>Terms of Service</h1>
            <p>Coming Soon</p>
            </Col>
  </Row>
    </TermsServices>
  </PageWrapper>
  <Footer/>
  </>
)
}
export default Terms;
