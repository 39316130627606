import React,{useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col, Carousel, Button, Image} from 'react-bootstrap';
import homepage from '../pagedata/homepage';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import AOS from 'aos';
import "aos/dist/aos.css";

const HomeWrapper = styled.div``;
const Hero = styled.section``;
const KeyFeatures = styled.section``;
const Testimonials = styled.section``;
const Clients = styled.section``;

function Home() {
  useEffect(() => {
    AOS.init({
        duration: 1000,
        useClassNames: true,
        initClassName: false,
        easing: 'linear',
    });
    AOS.refresh();
}, []);

  return(
    <>
   <Masthead/>
  <HomeWrapper>
    <Hero className='container-fluid herobanner'>
          <Row className="justify-content-center">
              <Col lg="10" className='banner'>
                  <Carousel controls={true}>
                      {homepage.herobanner.carousel.map((item, index) => (
                      <Carousel.Item key={index}>
                      <Row>
                          <Col lg="6" xl="5" className='left pr-5 pl-5 pl-lg-5 text-center text-lg-left'>
                              <h1 className=''>{item.heading}</h1>
                              <p className='mt-3' dangerouslySetInnerHTML={{ __html: item.subheading }}></p>
                              <Button variant="primary" className='rounded-3 pr-4 pl-4 mt-3' href={item.ctalink}>{item.ctatext}</Button>{' '}
                          </Col>
                          <Col lg="6" xl="7" className='pl-lg-5 pt-lg-5 pt-xl-0'><img src={item.imagesrc} className='ml-1 img-fluid' alt=""/></Col>
                      </Row>
                      </Carousel.Item>
                      ))}
                  </Carousel>
              </Col>
          </Row>
    </Hero>

    <KeyFeatures className="container-fluid homesection" data-aos="fade-up">
      <Row className='justify-content-center'>
        <Col lg="8" className='welcometxt pt-5 pb-5'>
          <h2>{homepage.keyfeatures.title}</h2>
          <Container fluid className='mt-5'>
          <Carousel controls={false}>
              {homepage.keyfeatures.items.map((item, index) => (
              <Carousel.Item key={index}>
              <Row className='mt-5'>
              <Col lg="4" className='left pr-5'>
              <Image src={item.imagesrc} />
              </Col>
              <Col lg="6" className='pl-lg-5'>
                <div>{item.text}</div>
                <div dangerouslySetInnerHTML={{ __html: item.features }}></div>
              </Col>
              </Row>
              </Carousel.Item>
              ))}
              </Carousel>         
          </Container>
        </Col>
      </Row>
    </KeyFeatures>

    <Testimonials className="container-fluid homesection testimonials mt-5" data-aos="fade-up">
    <Row className='justify-content-center'>
      <Col lg="8" className='pt-5 pb-5'>
      <h2>{homepage.testimonials.title}</h2>
      <Container fluid className='mt-5'>
          <Carousel controls={false}>
              {homepage.testimonials.items.map((item, index) => (
              <Carousel.Item key={index}>
              <Row className='mt-5'>
                <Col lg="4" className='left pr-5'>
                  <img src="" alt=""/>
                </Col>
                <Col lg="8" className='pl-5'>
                  <p className="lead">{item.text}</p>
                      <p>{item.name}</p>
                </Col>
              </Row>
              </Carousel.Item>
              ))}
              </Carousel>         
          </Container>
      </Col>
    </Row>
  </Testimonials>

  <Clients className="container-fluid homesection clients" data-aos="fade-up">
    <Row className='justify-content-center'>
      <Col className='col-lg-8 welcometxt pt-5 pb-5'>
        <h2>{homepage.clients.title}</h2>
        <Container fluid>
        <Row className='justify-content-center'>
        <Carousel controls={false}>
            {homepage.clients.items.map((item, index) => (
                <Carousel.Item key={index}>
              <Col lg="12" className='pb-3 pt-5 text-md-center justify-content-center' key={index}>
                <div><Image src="" alt='' /></div>
                <div>Coming Soon</div>
                
              </Col>
              </Carousel.Item>
            ))}
        
          </Carousel>
          </Row>
        </Container>
      </Col>
    </Row>
  </Clients>
  </HomeWrapper>
  <Footer/>
  </>
)
}
export default Home;
