import herobanner from "../Assets/images/resourcesbanner.png";
import resources1 from "../Assets/images/resources1.png";
import resources2 from "../Assets/images/resources2.png";
import resources3 from "../Assets/images/resources3.png";
import resources4 from "../Assets/images/resources4.png";
import resources5 from "../Assets/images/resources5.png";

const resourcesdata = {
  "page": {
    "title": "Resouces",
    "hero": 
      {
        "heading": "",
        "subhead": "",
        "banner":herobanner,
        "ctatext":"Click Here",
        "ctalink":"/solutions"
      },      
    "resources": {
      "about": "What's Next?",
      "items1":[
        {
          "heading": "Case Studies",
          "img": resources1,
          "link":""
        },
        {
          "heading": "Blogs",
          "img": resources2,
          "link":""

        },
        {
          "heading": "Online Help",
          "img": resources3,
          "link":""

        },
      ],
        "items2":[
          {
            "heading": "What’s Flowing in Next for Contract",
            "img": resources4,
            "link":""
  
          },
          {
            "heading": "What’s Flowing in Next for Invoice",
            "img":resources5,
            "link":""
  
          },
      ]
    }
  }
};

export default resourcesdata;