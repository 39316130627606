import React from 'react';
import { Col, Row, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import footer from '../pagedata/footer';
import navigation from '../pagedata/navigation';
function Footer() {
  return (
    <footer className='container-fluid'>
      <Row className='justify-content-center'>
        <Col lg={10}>
          <Row className='pb-3'>
            <Col lg={3} className='text-nd-center text-lg-left'>
              <img alt="ArkPetals" src={footer.address.logo} />
              <p className="pt-4" dangerouslySetInnerHTML={{ __html: footer.address.address }}></p>
              <p className="pt-4 mb-0"><FontAwesomeIcon icon={faPhone} className='orange mr-2' /><a href="tel:+918447446216" className='orange'>{footer.address.phone}</a></p>
              <p></p>
              <p><a href={`mailto:${footer.address.email}`}>{footer.address.email}</a></p>
            </Col>
            <Col lg={3} className='text-nd-center text-lg-left'>
              <h5 className="pb-4 orange">{footer.location.title}</h5>
              <img alt="map" src={require('../Assets/images/map.png')} />
            </Col>
            <Col lg={3} className='text-nd-center text-lg-left'>
              <h5 className="pb-4 pt-5 pt-lg-0 orange">{footer.links.label}</h5>
              <ListGroup flush="true">
                {navigation.routes.filter((i) => !i.contact).map((i) => (
                  <ListGroup.Item key={i.label} action href={i.path} className='pl-0 bg-transparent border-0 pt-1 pb-1 fnav'>{i.label}</ListGroup.Item>
                ))}
                {navigation.routes.filter((i) => i.contact).map((i) => (
                  <ListGroup.Item key={i.label} action href={i.path} className='pl-0 bg-transparent border-0 pt-1 pb-1 fnav'>Contact</ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            <Col lg={3} className='text-nd-center text-lg-left'>
              <h5 className="pb-4 orange">{footer.social.label}</h5>
              <p>{footer.social.text}</p>
              <ListGroup horizontal="true" className='social'>
                {footer.social.links.map((item, index) => (
                  <ListGroup.Item key={index} action className='social__list bg-transparent border-0 purple pl-0' href={item.link} target='_blank' rel='noreferrer'><FontAwesomeIcon icon={item.icon} /></ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
          <Row className='copyright pt-4 border-top border-secondary'>
            <div className="col-lg-6 col-12 text-center text-md-center text-lg-left">
              <p>{footer.copyright.text}</p>
            </div>
            <div className="col-lg-6 col-12 text-lg-right text-center">
              <ul className="nav justify-content-center justify-content-lg-end">
                {footer.copyright.links.map((item, index) => (
                  <li className="pl-lg-0" key={index}>
                    <a rel='noreferrer' href={item.link} target='_blank'>{item.label}</a></li>
                ))}
              </ul>
            </div>
          </Row>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer;
