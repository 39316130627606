import React from 'react';
import { Row, Col } from 'react-bootstrap';
import careersdata from '../pagedata/careers';
import styled from 'styled-components';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';

const PageWrapper = styled.div``;
const Hero = styled.section``;
const Career = styled.section``;

function Careers() {
  return(
    <>
    <Masthead/>
  <PageWrapper className="container-xxl careers p-0">
 <Hero className='container-fluid herobanner'>
      <Row className="justify-content-center">
        <Col lg="10">
        <Row className="justify-content-center pr-5 pl-5 pr-lg-0 pl-lg-0">
            <Col lg="6" className='left pr-lg-5 text-center text-lg-left'>
              <h1>{careersdata.page.hero.heading}</h1>
              <p>{careersdata.page.hero.subhead}</p>
            </Col>
            <Col lg="6" className='pl-5'><img src={careersdata.page.hero.banner} className='img-fluid' alt=""/></Col>
          </Row>
        </Col>
      </Row>
    </Hero>

    <Career className='container-fluid pt-5 pb-5 careercontent'>
  <Row className='justify-content-center'>
      <Col lg="8">
      <Row>
      <Col lg="12">
      <p dangerouslySetInnerHTML={{ __html: careersdata.page.about }}></p>
       <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
      </Col>
    </Row>
    </Col>
    </Row>
  </Career>
  </PageWrapper>
  <Footer/>
  </>
  )
  }
  export default Careers;
