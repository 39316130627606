import React,{useState, useEffect }from 'react';
import {useLocation} from 'react-router-dom';
import { Nav, Navbar,Image} from 'react-bootstrap';
import navigation from '../pagedata/navigation';
import styled from 'styled-components';
const Header = styled.header``;

function Masthead (){

  const location = useLocation();
  const [url, setUrl] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return(
  <Header className={scrollPosition > 100 ? "container-xxl fixed-top slide-in" : "container-xxl fixed-top"}>
    <Navbar expand='lg' className='navbar-expand-lg navbar-dark pt-lg-4 pb-lg-2' id="navbar">
      <Navbar.Brand href='/'><Image className='img-fluid' src={navigation.arklogo} alt='Ark Petals' id="logo" /></Navbar.Brand>
      <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
      <Navbar.Collapse className='justify-content-end'  >
        <div className='offcanvaslogo'><Image className='img-fluid pt-3 pb-3 d-lg-none' src={navigation.arklogo} alt='Ark Petals' /></div>
        <Nav className='ml-auto navbar-light'>
          {navigation.routes.filter((i) => !i.auth && !i.contact).map((i) => (
            <Nav.Item key={i.label} className={url === i.path ?" active" : ""}>
              <Nav.Link href={i.path}>{i.label}</Nav.Link>
            </Nav.Item>
          ))}
        {navigation.routes.filter((i) => i.contact).map((i) => (
            <Nav.Item key={i.label}>
              <Nav.Link key={i.path} href="tel:+918447446216" className='contactbtn rounded-5 pl-4 pr-4'  dangerouslySetInnerHTML={{ __html: i.label }}></Nav.Link>
            </Nav.Item>
          ))}
          {navigation.routes.filter((i) => i.auth).map((i) => (
            <Nav.Item key={i.label}>
              <Nav.Link key={i.path} href={i.path} className='demobtn pl-3 pr-3 pt-2 pb-2'>{i.label}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Header>
  )
}

export default Masthead;
