
const navigation = {
  "arklogo":require('../Assets/images/arkpetals-logo.png'),
  routes:[
    {
      label: "Home",
      path: '/',
    },
    {
      label: 'About Us',
      path: '/about',
    },
    {
      label: 'Solutions',
      path: '/solutions',
    },
    {
      label: 'Pricing',
      path: '/pricing',
    },
    {
      label: 'Resources',
      path: '/resources',
    },
    {
      label: 'Careers',
      path: '/careers',
    },
    {
      label: 'Contact Us',
      path: '/contact',
    },
    {
      auth:true,
      label: 'Request for Demo',
      path: '/contact?reqtype=requestfordemo',
    },
  ]
}
  
  export default navigation;
  
