const pricingdata = {
  "page": {
    "title": "Solutions",
    "hero":
    {
      "heading": "Pricing plan for Ark Docs",
      "subhead": "Choose the plan that best suits you."
    },
    "plans": [
      {
        "name": "Basic",
        "priceMonthly": "$",
        "priceYearly": "$",
        "specs": [
          {
            "flag": true,
            "spec": "150 GB Storage",
            "detail": "-"
          },
          {
            "flag": true,
            "spec": "Up to 5 User License"
          },
          {
            "flag": true,
            "spec": "Up to 5 Approval E-Sign"
          },
          {
            "flag": false,
            "spec": " My Zone"
          },
          {
            "flag": false,
            "spec": "Workflow"
          },
          {
            "flag": false,
            "spec": "Archival Support"
          },
          {
            "flag": false,
            "spec": "API"
          },
          {
            "flag": false,
            "spec": "Document Numbering"
          },
          {
            "flag": false,
            "spec": "Email Templates"
          },
          {
            "flag": false,
            "spec": "Priority Support "
          },
          {
            "flag": false,
            "spec": "Multi Language Support"
          },
          {
            "flag": false,
            "spec": "Time Zone Configuration"
          },
          {
            "flag": false,
            "spec": "Custom Report"
          },
          {
            "flag": false,
            "spec": "OCR"
          },
          {
            "flag": false,
            "spec": "Plug-in (Email Integration/ Physical Folder)"
          },
          {
            "flag": false,
            "spec": "Recycle Bin"
          },
          {
            "flag": false,
            "spec": "External User Reviewing the Document"
          },
          {
            "flag": false,
            "spec": "Merging of documents"
          },
          {
            "flag": false,
            "spec": "Local Backup"
          },
          {
            "flag": false,
            "spec": "Calendar, Queue,"
          },
          {
            "flag": false,
            "spec": "Delgation, Alternate Actor"
          }
        ],
        ctaText: "Purchase"
      },
      {
        "name": "Standard",
        "priceMonthly": "$",
        "priceYearly": "$",
        "specs": [
          {
            "flag": true,
            "spec": "250 GB Storage"
          },
          {
            "flag": true,
            "spec": "Up to 5 User License"
          },
          {
            "flag": true,
            "spec": "Up to 5 Approval E-Sign",
            "detail": "-"
          },
          {
            "flag": true,
            "spec": " My Zone"
          },
          {
            "flag": true,
            "spec": "Workflow"
          },
          {
            "flag": true,
            "spec": "Archival Support"
          },
          {
            "flag": false,
            "spec": "API"
          },
          {
            "flag": false,
            "spec": "Document Numbering"
          },
          {
            "flag": false,
            "spec": "Email Templates"
          },
          {
            "flag": false,
            "spec": "Priority Support "
          },
          {
            "flag": false,
            "spec": "Multi Language Support"
          },
          {
            "flag": false,
            "spec": "Time Zone Configuration"
          },
          {
            "flag": false,
            "spec": "Custom Report"
          },
          {
            "flag": false,
            "spec": "OCR"
          },
          {
            "flag": false,
            "spec": "Plug-in (Email Integration/ Physical Folder)"
          },
          {
            "flag": false,
            "spec": "Recycle Bin"
          },
          {
            "flag": false,
            "spec": "External User Reviewing the Document"
          },
          {
            "flag": false,
            "spec": "Merging of documents"
          },
          {
            "flag": false,
            "spec": "Local Backup"
          },
          {
            "flag": true,
            "spec": "Calendar, Queue,",
            "detail": "-"
          },
          {
            "flag": true,
            "spec": "Delgation, Alternate Actor"

          }
        ],
        ctaText: "Purchase"
      },
      {
        "name": "Professional",
        "priceMonthly": "$",
        "priceYearly": "$",
        "specs": [
          {
            "flag": true,
            "spec": "500 GB Storage"
          },
          {
            "flag": true,
            "spec": "Up to 5 User License"
          },
          {
            "flag": true,
            "spec": "Up to 5 Approval E-Sign"
          },
          {
            "flag": true,
            "spec": " My Zone"
          },
          {
            "flag": true,
            "spec": "Workflow"
          },
          {
            "flag": true,
            "spec": "Archival Support"
          },
          {
            "flag": true,
            "spec": "API"
          },
          {
            "flag": true,
            "spec": "Document Numbering"
          },
          {
            "flag": true,
            "spec": "Email Templates"
          },
          {
            "flag": true,
            "spec": "Priority Support ",
            "detail": "-"
          },
          {
            "flag": true,
            "spec": "Multi Language Support"
          },
          {
            "flag": false,
            "spec": "Time Zone Configuration"
          },
          {
            "flag": true,
            "spec": "Custom Report"
          },
          {
            "flag": true,
            "spec": "OCR"
          },
          {
            "flag": true,
            "spec": "Plug-in (Email Integration/ Physical Folder)"
          },
          {
            "flag": true,
            "spec": "Recycle Bin"
          },
          {
            "flag": false,
            "spec": "External User Reviewing the Document"
          },
          {
            "flag": false,
            "spec": "Merging of documents"
          },
          {
            "flag": false,
            "spec": "Local Backup"
          },
          {
            "flag": true,
            "spec": "Calendar, Queue,"
          },
          {
            "flag": true,
            "spec": "Delgation, Alternate Actor"
          }
        ],
        ctaText: "Purchase"
      },
      {
        "name": "Enterprise",
        "priceMonthly": "$",
        "priceYearly": "$",
        "specs": [
          {
            "flag": true,
            "spec": "1TB Above Storage"
          },
          {
            "flag": true,
            "spec": "Up to 5 User License"
          },
          {
            "flag": true,
            "spec": "Up to 5 Approval E-Sign"
          },
          {
            "flag": true,
            "spec": " My Zone"
          },
          {
            "flag": true,
            "spec": "Workflow"
          },
          {
            "flag": true,
            "spec": "Archival Support"
          },
          {
            "flag": true,
            "spec": "API"
          },
          {
            "flag": true,
            "spec": "Document Numbering"
          },
          {
            "flag": true,
            "spec": "Email Templates"
          },
          {
            "flag": true,
            "spec": "Priority Support "
          },
          {
            "flag": true,
            "spec": "Multi Language Support"
          },
          {
            "flag": true,
            "spec": "Time Zone Configuration"
          },
          {
            "flag": true,
            "spec": "Custom Report"
          },
          {
            "flag": true,
            "spec": "OCR"
          },
          {
            "flag": true,
            "spec": "Plug-in (Email Integration/ Physical Folder)"
          },
          {
            "flag": true,
            "spec": "Recycle Bin"
          },
          {
            "flag": true,
            "spec": "External User Reviewing the Document"
          },
          {
            "flag": true,
            "spec": "Merging of documents"
          },
          {
            "flag": true,
            "spec": "Local Backup"
          },
          {
            "flag": true,
            "spec": "Calendar, Queue,"
          },
          {
            "flag": true,
            "spec": "Delgation, Alternate Actor"
          }
        ],
        ctaText: "Purchase"
      }
    ],
    "info": "<p>For pricing you can reach us on <a href='mailto:sales@arkpetals.com'>sales@arkpetals.com</a> with your requirement.</p>"
  }
};




export default pricingdata;
