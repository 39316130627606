import React,{useState,useEffect} from 'react';
import { Row, Col, Table,Button, Tooltip,Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import pricingdata from '../pagedata/pricing';
import FAQComponent from '../components/FAQ';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import AOS from 'aos';
import "aos/dist/aos.css";

const PageWrapper = styled.div``;
const Hero = styled.section``;
const Faq = styled.section``;

function Pricing(){
  const [isMonthly, setMonthly] = useState(true);
  function togglePrice() {
    setMonthly(!isMonthly);
  }
  
  useEffect(() => {
    AOS.init({
        duration: 1000,
        useClassNames: true,
        initClassName: false,
        easing: 'linear',
    });
    AOS.refresh();
}, []);


  return(
    <>
    <Masthead/>
  <PageWrapper className="container-xxl pricing">
    <Hero className='container-fluid herobanner pb-5'>
      <Row className="justify-content-center pt-5 pb-5" data-aos="fade-up">
        <Col lg="10 pt-5">
          <Row className='pb-5'>
          <Col lg="2" className='text-center'>
         </Col>
            <Col lg="8" className='text-center'>
            <h3>{pricingdata.page.hero.heading}</h3>
            <p>{pricingdata.page.hero.subhead}</p>
            </Col>
            <Col lg="2" className='text-center text-light'>
            <Form>
       <Form.Check type="switch" id="custom-switch" label={isMonthly ? "Monthly" : "Yearly"} onClick={togglePrice}/>
      </Form>
            </Col>
          </Row>
          <Row>
          {pricingdata.page.plans.map((item, index) => (
           <Col lg="3" className=''  key={index}>
          <Table borderless>
          <thead>
          <tr>
          <th>{item.name}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td className='price'>{isMonthly ? item.priceMonthly : item.priceYearly}</td>
          </tr>
          <tr>
          <td className='details'>
          
            <ul>
              {item.specs.map((spec, i) => (
                <>
                 {spec.flag ? <li key={i}>{spec.spec} {spec.detail ? <OverlayTrigger 
        delay={{ hide: 450, show: 200 }} 
        overlay={(props) => ( 
          <Tooltip {...props}> 
           {spec.detail}
          </Tooltip> 
        )} 
        placement="bottom"
      ><Button variant="warning"><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon></Button> 
      </OverlayTrigger> : ''}</li> : <li key={i} className='fade'>{spec.spec} </li>} 
                 </>
              ))}
            </ul>
          </td>
          </tr>
          <tr className='CTA'>
          <td className='text-center  pb-5'><Button variant="primary" className='rounded-3 pr-5 pl-5'>{item.ctaText}</Button>{' '}</td>
          </tr>
          </tbody>
          </Table>
          </Col>
          ))}
      </Row>
      </Col>
      </Row>
      <Row className="justify-content-center info" data-aos="fade-up">
            <Col lg="10" className='text-center'>
            <p dangerouslySetInnerHTML={{ __html: pricingdata.page.info }}></p>
            </Col>
        </Row>
      </Hero>

      <Faq className='faq container-fluid' data-aos="fade-up">
      <Row className="justify-content-center pt-5 pb-5">
        <Col lg="10">
        <FAQComponent />
        </Col>
      </Row>
      </Faq>
  </PageWrapper>
  <Footer/>
  </>
)
}
export default Pricing;
