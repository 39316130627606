import React from 'react';
import  faqdata from '../pagedata/faq';
import { Row, Col} from 'react-bootstrap';
import AccordionDisplay from '../components/Accordion';

function FAQ(){
return(
<>
<Row>
<Col lg="12" className='text-center'>
<h3>{faqdata.page.title}</h3>
</Col>
</Row>

<Row className='justify-content-center'>
<Col lg="10" className='mt-5'>
{faqdata.page.faqs.map((i, index) => (
<Row key={index}>
    <Col>
    <AccordionDisplay title={i.question} arrowPosition="left" forceDefaultHide={true}>
           <div>
           {i.answer}
           </div>
         </AccordionDisplay>
    </Col>
</Row>
))}
</Col>
</Row>
</>
) 
}

export default FAQ;

