import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';

// import footerlogo from "../Assets/images/arkpetalslogo-footer.png";
import footerlogo from "../Assets/images/arkpetals-logo_footer.png";

const footer = {
  "address": {
    "logo": footerlogo,
    "address": "Ark Petals Software Private Limited,<br/>Obeya Pulse #7,<br/>1, Ulsoor Rd, Halasuru,<br/>Yellappa Chetty Layout,<br/>Sivanchetti Gardens,<br/>Bengaluru,<br/>Karnataka 560042",
    "title": "Address",
    "phone": "+91 831-095-3923",
    "email": "info@arkpetals.com",
  },
  "links": {
    "label": "Links"
  },
  "social": {
    "label": "Stay Connected",
    "text": "Follow us on social media to find out the latest updates",
    "links": [
      {
        "name": "Youtube",
        "link": "https://www.youtube.com/@Arkpetals",
        "icon": faYoutube,
      },
      {
        "name": "faFacebook",
        "link": "https://facebook.com",
        "icon": faFacebookF,
      },
      {
        "name": "fa",
        "link": "https://www.linkedin.com/company/ark-petals/",
        "icon": faLinkedin,
      }
    ]
  },
  "location": {
    "title": "Location",
  },
  "copyright": {
    "text": "© 2024, Ark Petals",
    "links": [
      {
        "label": "Privacy Policy",
        "link": "/privacypolicy"
      },
      {
        "label": "Terms of Service",
        "link": "/terms"
      },
    ]
  }
};

export default footer;
